.contact {
    display: flex;
    margin-bottom: -100px;
    @include tablet_vert_screen {
        margin-bottom: 0;
    }
    .contact__img {
        overflow: hidden;
        @include md {
            display: none;
        }
        .img__wrap {
            img {
                height: 100%;
            }
        }
    }
    .contact__content {
        display: flex;
        align-items: center;
        padding-left: 50px;
        margin: 60px 0;
        @include md {
            margin: 70px 0 0;
            padding-left: 15px !important;
        }
    }
}