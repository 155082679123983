.ranking {
    margin-bottom: 0 !important;
    border-spacing: 0 2px;
    border-collapse: separate;
    border-spacing: 0 10px;
    .collection {
        display: flex;
        align-items: center;
        .media {
            position: relative;
            .has_number {
                position: absolute;
                right: -5%;
                top: 75%;
                width: 22px;
                height: 22px;
                color: $color_white;
                background: $color_grad;
                border: 2px solid $color_white;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: $font_size_small;
            }
            .collection__img {
                width: 4.5rem;
                height: 4.5rem;
                box-shadow: $shadow_1;
                border-radius: 5px;
                border: 3px solid #fff;
                object-fit: cover;
                transition: all 0.35s $transition_function;
            }
        }
        .title {
            font-weight: 400;
        }
    }
    .avatars {
        display: flex;
        align-items: center;
        .media {
            position: relative;
            .badge {
                position: absolute;
                bottom: 0;
                right: -7px;
                img {
                    width: 100%;
                }
            }
            .has_number {
                position: absolute;
                left: -12%;
                top: 40%;
                width: 22px;
                height: 22px;
                color: $color_white;
                background-color: $color_black;
                border: 2px solid $color_white;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: $font_size_small;
                &.top {
                    background: $color_grad;
                    left: 70%;
                    top: 4%;
                }
            }
            &.has_border {
                box-sizing: border-box;
                padding: 4px;
                border-radius: 100%;
                background: $color_grad;
            }
        }
        .avatars_name {
            font-weight: 700;
            transition: 0.3s;
            &.large {
                font-size: $font_size_large;
            }
            &:hover {
                color: $color_brand;
            }
        }
        .price {
            font-size: $font_size_small;
        }
        .details {
            display: flex;
            justify-content: space-between;
            background: $color_hard_light;
            padding: 20px;
            border-radius: 10px;
            box-shadow: $shadow_1;
            width: 100%;
        }
        .collection__img {
            width: 4.5rem;
            height: 4.5rem;
            box-shadow: $shadow_1;
            border-radius: 50% !important;
            border: 3px solid #fff;
            object-fit: cover;
            transition: all 0.35s $transition_function;
            &.avatar-sm {
                width: 2rem;
                height: 2rem;
            }
            &.avatar-md {
                width: 5rem;
                height: 5rem;
                @include sm {
                    width: 4rem;
                    height: 4rem;
                }
            }
            &.avatar-lg {
                width: 6rem;
                height: 6rem;
            }
        }
    }
    th {
        padding-left: 20px;
        padding-right: 20px;
        i {
            color: $color_black;
            vertical-align: middle;
            font-weight: normal;
            font-size: 17.5px;
        }
        span {
            color: $color_black;
            font-weight: bold;
            vertical-align: middle;
        }
    }
    tr {
        background: transparent;
        td {
            vertical-align: middle;
            padding: 15px 20px;
            background: $color_grey;
            white-space: nowrap;
        }
        // &.white {
        //     td {
        //         background: white;
        //     }
        // }
    }
    .table * {
        border: none !important;
    }

    table tbody td:first-child {
        border-radius: 10px 0 0 10px;
    }

    table tbody td:last-child {
        border-radius: 0 10px 10px 0;
    }
}
