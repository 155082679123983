// -----------🌈🌈  Padding 🌈🌈-----------
.p-0 {
  padding: 0px;
}

.p-1 {
  padding: 1px;
}

.p-2 {
  padding: 2px;
}

.p-3 {
  padding: 3px;
}

.p-4 {
  padding: 4px;
}

.p-5 {
  padding: 5px;
}

.p-10 {
  padding: 10px !important;
}

.p-15 {
  padding: 15px !important;
}

.p-20 {
  padding: 20px;
}

.p-25 {
  padding: 25px;
}

.p-30 {
  padding: 30px;
}

.p-35 {
  padding: 35px;
}

.p-40 {
  padding: 40px;
}

.p-45 {
  padding: 45px;
}

.p-50 {
  padding: 50px;
}

.p-55 {
  padding: 55px;
}

.p-60 {
  padding: 60px;
}

.p-65 {
  padding: 65px;
}

.p-70 {
  padding: 70px;
}

.p-75 {
  padding: 75px;
}

.p-80 {
  padding: 80px;
}

.p-85 {
  padding: 85px;
}

.p-90 {
  padding: 90px;
}

.p-95 {
  padding: 95px;
}

.p-100 {
  padding: 100px;
}

.p-105 {
  padding: 105px;
}

.p-110 {
  padding: 110px;
}

.p-115 {
  padding: 115px;
}

.p-120 {
  padding: 120px;
}

.p-px {
  padding: 1px;
}

.p-0\.5 {
  padding: 0.5px;
}

.p-1\.5 {
  padding: 1.5px;
}

.p-2\.5 {
  padding: 2.5px;
}

.p-3\.5 {
  padding: 3.5px;
}

.py-0 {
  padding-top: 0px;
  padding-bottom: 0px;
}

.px-0 {
  padding-left: 0px;
  padding-right: 0px;
}

.py-1 {
  padding-top: 1px;
  padding-bottom: 1px;
}

.px-1 {
  padding-left: 1px;
  padding-right: 1px;
}

.py-2 {
  padding-top: 2px;
  padding-bottom: 2px;
}

.px-2 {
  padding-left: 2px;
  padding-right: 2px;
}

.py-3 {
  padding-top: 3px;
  padding-bottom: 3px;
}

.px-3 {
  padding-left: 3px;
  padding-right: 3px;
}

.py-4 {
  padding-top: 4px;
  padding-bottom: 4px;
}

.px-4 {
  padding-left: 4px;
  padding-right: 4px;
}

.py-5 {
  padding-top: 5px;
  padding-bottom: 5px;
}

.px-5 {
  padding-left: 5px;
  padding-right: 5px;
}

.py-10 {
  padding-top: 10px;
  padding-bottom: 10px;
}

.px-10 {
  padding-left: 10px;
  padding-right: 10px;
}

.py-15 {
  padding-top: 15px;
  padding-bottom: 15px;
}

.px-15 {
  padding-left: 15px;
  padding-right: 15px;
}

.py-20 {
  padding-top: 20px;
  padding-bottom: 20px;
}

.px-20 {
  padding-left: 20px;
  padding-right: 20px;
}

.py-25 {
  padding-top: 25px;
  padding-bottom: 25px;
}

.px-25 {
  padding-left: 25px;
  padding-right: 25px;
}

.py-30 {
  padding-top: 30px;
  padding-bottom: 30px;
}

.px-30 {
  padding-left: 30px;
  padding-right: 30px;
}

.py-35 {
  padding-top: 35px;
  padding-bottom: 35px;
}

.px-35 {
  padding-left: 35px;
  padding-right: 35px;
}

.py-40 {
  padding-top: 40px;
  padding-bottom: 40px;
}

.px-40 {
  padding-left: 40px;
  padding-right: 40px;
}

.py-45 {
  padding-top: 45px;
  padding-bottom: 45px;
}

.px-45 {
  padding-left: 45px;
  padding-right: 45px;
}

.py-50 {
  padding-top: 50px;
  padding-bottom: 50px;
}

.px-50 {
  padding-left: 50px;
  padding-right: 50px;
}

.py-55 {
  padding-top: 55px;
  padding-bottom: 55px;
}

.px-55 {
  padding-left: 55px;
  padding-right: 55px;
}

.py-60 {
  padding-top: 60px;
  padding-bottom: 60px;
}

.px-60 {
  padding-left: 60px;
  padding-right: 60px;
}

.py-65 {
  padding-top: 65px;
  padding-bottom: 65px;
}

.px-65 {
  padding-left: 65px;
  padding-right: 65px;
}

.py-70 {
  padding-top: 70px;
  padding-bottom: 70px;
}

.px-70 {
  padding-left: 70px;
  padding-right: 70px;
}

.py-75 {
  padding-top: 75px;
  padding-bottom: 75px;
}

.px-75 {
  padding-left: 75px;
  padding-right: 75px;
}

.py-80 {
  padding-top: 80px;
  padding-bottom: 80px;
}

.px-80 {
  padding-left: 80px;
  padding-right: 80px;
}

.py-85 {
  padding-top: 85px;
  padding-bottom: 85px;
}

.px-85 {
  padding-left: 85px;
  padding-right: 85px;
}

.py-90 {
  padding-top: 90px;
  padding-bottom: 90px;
}

.px-90 {
  padding-left: 90px;
  padding-right: 90px;
}

.py-95 {
  padding-top: 95px;
  padding-bottom: 95px;
}

.px-95 {
  padding-left: 95px;
  padding-right: 95px;
}

.py-100 {
  padding-top: 100px;
  padding-bottom: 100px;
}

.px-100 {
  padding-left: 100px;
  padding-right: 100px;
}

.py-105 {
  padding-top: 105px;
  padding-bottom: 105px;
}

.px-105 {
  padding-left: 105px;
  padding-right: 105px;
}

.py-110 {
  padding-top: 110px;
  padding-bottom: 110px;
}

.px-110 {
  padding-left: 110px;
  padding-right: 110px;
}

.py-115 {
  padding-top: 115px;
  padding-bottom: 115px;
}

.px-115 {
  padding-left: 115px;
  padding-right: 115px;
}

.py-120 {
  padding-top: 120px;
  padding-bottom: 120px;
}

.px-120 {
  padding-left: 120px;
  padding-right: 120px;
}

.py-px {
  padding-top: 1px;
  padding-bottom: 1px;
}

.px-px {
  padding-left: 1px;
  padding-right: 1px;
}

.py-0\.5 {
  padding-top: 0.5px;
  padding-bottom: 0.5px;
}

.px-0\.5 {
  padding-left: 0.5px;
  padding-right: 0.5px;
}

.py-1\.5 {
  padding-top: 1.5px;
  padding-bottom: 1.5px;
}

.px-1\.5 {
  padding-left: 1.5px;
  padding-right: 1.5px;
}

.py-2\.5 {
  padding-top: 2.5px;
  padding-bottom: 2.5px;
}

.px-2\.5 {
  padding-left: 2.5px;
  padding-right: 2.5px;
}

.py-3\.5 {
  padding-top: 3.5px;
  padding-bottom: 3.5px;
}

.px-3\.5 {
  padding-left: 3.5px;
  padding-right: 3.5px;
}

.pt-0 {
  padding-top: 0px;
}

.pr-0 {
  padding-right: 0px;
}

.pb-0 {
  padding-bottom: 0px;
}

.pl-0 {
  padding-left: 0px;
}

.pt-1 {
  padding-top: 1px;
}

.pr-1 {
  padding-right: 1px;
}

.pb-1 {
  padding-bottom: 1px;
}

.pl-1 {
  padding-left: 1px;
}

.pt-2 {
  padding-top: 2px;
}

.pr-2 {
  padding-right: 2px;
}

.pb-2 {
  padding-bottom: 2px;
}

.pl-2 {
  padding-left: 2px;
}

.pt-3 {
  padding-top: 3px;
}

.pr-3 {
  padding-right: 3px;
}

.pb-3 {
  padding-bottom: 3px;
}

.pl-3 {
  padding-left: 3px;
}

.pt-4 {
  padding-top: 4px;
}

.pr-4 {
  padding-right: 4px;
}

.pb-4 {
  padding-bottom: 4px;
}

.pl-4 {
  padding-left: 4px;
}

.pt-5 {
  padding-top: 5px;
}

.pr-5 {
  padding-right: 5px;
}

.pb-5 {
  padding-bottom: 5px;
}

.pl-5 {
  padding-left: 5px;
}

.pt-10 {
  padding-top: 10px;
}

.pr-10 {
  padding-right: 10px;
}

.pb-10 {
  padding-bottom: 10px;
}

.pl-10 {
  padding-left: 10px;
}

.pt-15 {
  padding-top: 15px;
}

.pr-15 {
  padding-right: 15px;
}

.pb-15 {
  padding-bottom: 15px;
}

.pl-15 {
  padding-left: 15px;
}

.pt-20 {
  padding-top: 20px;
}

.pr-20 {
  padding-right: 20px;
}

.pb-20 {
  padding-bottom: 20px;
}

.pl-20 {
  padding-left: 20px;
}

.pt-25 {
  padding-top: 25px;
}

.pr-25 {
  padding-right: 25px;
}

.pb-25 {
  padding-bottom: 25px;
}

.pl-25 {
  padding-left: 25px;
}

.pt-30 {
  padding-top: 30px;
}

.pr-30 {
  padding-right: 30px;
}

.pb-30 {
  padding-bottom: 30px;
}

.pl-30 {
  padding-left: 30px;
}

.pt-35 {
  padding-top: 35px;
}

.pr-35 {
  padding-right: 35px;
}

.pb-35 {
  padding-bottom: 35px;
}

.pl-35 {
  padding-left: 35px;
}

.pt-40 {
  padding-top: 40px;
}

.pr-40 {
  padding-right: 40px;
}

.pb-40 {
  padding-bottom: 40px;
}

.pl-40 {
  padding-left: 40px;
}

.pt-45 {
  padding-top: 45px;
}

.pr-45 {
  padding-right: 45px;
}

.pb-45 {
  padding-bottom: 45px;
}

.pl-45 {
  padding-left: 45px;
}

.pt-50 {
  padding-top: 50px;
}

.pr-50 {
  padding-right: 50px;
}

.pb-50 {
  padding-bottom: 50px;
}

.pl-50 {
  padding-left: 50px;
}

.pt-55 {
  padding-top: 55px;
}

.pr-55 {
  padding-right: 55px;
}

.pb-55 {
  padding-bottom: 55px;
}

.pl-55 {
  padding-left: 55px;
}

.pt-60 {
  padding-top: 60px;
}

.pr-60 {
  padding-right: 60px;
}

.pb-60 {
  padding-bottom: 60px;
}

.pl-60 {
  padding-left: 60px;
}

.pt-65 {
  padding-top: 65px;
}

.pr-65 {
  padding-right: 65px;
}

.pb-65 {
  padding-bottom: 65px;
}

.pl-65 {
  padding-left: 65px;
}

.pt-70 {
  padding-top: 70px;
}

.pr-70 {
  padding-right: 70px;
}

.pb-70 {
  padding-bottom: 70px;
}

.pl-70 {
  padding-left: 70px;
}

.pt-75 {
  padding-top: 75px;
}

.pr-75 {
  padding-right: 75px;
}

.pb-75 {
  padding-bottom: 75px;
}

.pl-75 {
  padding-left: 75px;
}

.pt-80 {
  padding-top: 80px;
}

.pr-80 {
  padding-right: 80px;
}

.pb-80 {
  padding-bottom: 80px;
}

.pl-80 {
  padding-left: 80px;
}

.pt-85 {
  padding-top: 85px;
}

.pr-85 {
  padding-right: 85px;
}

.pb-85 {
  padding-bottom: 85px;
}

.pl-85 {
  padding-left: 85px;
}

.pt-90 {
  padding-top: 90px;
}

.pr-90 {
  padding-right: 90px;
}

.pb-90 {
  padding-bottom: 90px;
}

.pl-90 {
  padding-left: 90px;
}

.pt-95 {
  padding-top: 95px;
}

.pr-95 {
  padding-right: 95px;
}

.pb-95 {
  padding-bottom: 95px;
}

.pl-95 {
  padding-left: 95px;
}

.pt-100 {
  padding-top: 100px;
}

.pr-100 {
  padding-right: 100px;
}

.pb-100 {
  padding-bottom: 100px;
}

.pl-100 {
  padding-left: 100px;
}

.pt-105 {
  padding-top: 105px;
}

.pr-105 {
  padding-right: 105px;
}

.pb-105 {
  padding-bottom: 105px;
}

.pl-105 {
  padding-left: 105px;
}

.pt-110 {
  padding-top: 110px;
}

.pr-110 {
  padding-right: 110px;
}

.pb-110 {
  padding-bottom: 110px;
}

.pl-110 {
  padding-left: 110px;
}

.pt-115 {
  padding-top: 115px;
}

.pr-115 {
  padding-right: 115px;
}

.pb-115 {
  padding-bottom: 115px;
}

.pl-115 {
  padding-left: 115px;
}

.pt-120 {
  padding-top: 120px;
}

.pr-120 {
  padding-right: 120px;
}

.pb-120 {
  padding-bottom: 120px;
}

.pl-120 {
  padding-left: 120px;
}

.pt-px {
  padding-top: 1px;
}

.pr-px {
  padding-right: 1px;
}

.pb-px {
  padding-bottom: 1px;
}

.pl-px {
  padding-left: 1px;
}

.pt-0\.5 {
  padding-top: 0.5px;
}

.pr-0\.5 {
  padding-right: 0.5px;
}

.pb-0\.5 {
  padding-bottom: 0.5px;
}

.pl-0\.5 {
  padding-left: 0.5px;
}

.pt-1\.5 {
  padding-top: 1.5px;
}

.pr-1\.5 {
  padding-right: 1.5px;
}

.pb-1\.5 {
  padding-bottom: 1.5px;
}

.pl-1\.5 {
  padding-left: 1.5px;
}

.pt-2\.5 {
  padding-top: 2.5px;
}

.pr-2\.5 {
  padding-right: 2.5px;
}

.pb-2\.5 {
  padding-bottom: 2.5px;
}

.pl-2\.5 {
  padding-left: 2.5px;
}

.pt-3\.5 {
  padding-top: 3.5px;
}

.pr-3\.5 {
  padding-right: 3.5px;
}

.pb-3\.5 {
  padding-bottom: 3.5px;
}

.pl-3\.5 {
  padding-left: 3.5px;
}

// -----------🌈🌈🌈🌈🌈🌈🌈🌈  Small screen 🌈🌈🌈🌈🌈🌈🌈🌈-----------
@include sm_screen {
  .sm\:p-0 {
    padding: 0px;
  }

  .sm\:p-1 {
    padding: 1px;
  }

  .sm\:p-2 {
    padding: 2px;
  }

  .sm\:p-3 {
    padding: 3px;
  }

  .sm\:p-4 {
    padding: 4px;
  }

  .sm\:p-5 {
    padding: 5px;
  }

  .sm\:p-10 {
    padding: 10px;
  }

  .sm\:p-15 {
    padding: 15px;
  }

  .sm\:p-20 {
    padding: 20px;
  }

  .sm\:p-25 {
    padding: 25px;
  }

  .sm\:p-30 {
    padding: 30px;
  }

  .sm\:p-35 {
    padding: 35px;
  }

  .sm\:p-40 {
    padding: 40px;
  }

  .sm\:p-45 {
    padding: 45px;
  }

  .sm\:p-50 {
    padding: 50px;
  }

  .sm\:p-55 {
    padding: 55px;
  }

  .sm\:p-60 {
    padding: 60px;
  }

  .sm\:p-65 {
    padding: 65px;
  }

  .sm\:p-70 {
    padding: 70px;
  }

  .sm\:p-75 {
    padding: 75px;
  }

  .sm\:p-80 {
    padding: 80px;
  }

  .sm\:p-85 {
    padding: 85px;
  }

  .sm\:p-90 {
    padding: 90px;
  }

  .sm\:p-95 {
    padding: 95px;
  }

  .sm\:p-100 {
    padding: 100px;
  }

  .sm\:p-105 {
    padding: 105px;
  }

  .sm\:p-110 {
    padding: 110px;
  }

  .sm\:p-115 {
    padding: 115px;
  }

  .sm\:p-120 {
    padding: 120px;
  }

  .sm\:p-px {
    padding: 1px;
  }

  .sm\:p-0\.5 {
    padding: 0.5px;
  }

  .sm\:p-1\.5 {
    padding: 1.5px;
  }

  .sm\:p-2\.5 {
    padding: 2.5px;
  }

  .sm\:p-3\.5 {
    padding: 3.5px;
  }

  .sm\:py-0 {
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .sm\:px-0 {
    padding-left: 0px;
    padding-right: 0px;
  }

  .sm\:py-1 {
    padding-top: 1px;
    padding-bottom: 1px;
  }

  .sm\:px-1 {
    padding-left: 1px;
    padding-right: 1px;
  }

  .sm\:py-2 {
    padding-top: 2px;
    padding-bottom: 2px;
  }

  .sm\:px-2 {
    padding-left: 2px;
    padding-right: 2px;
  }

  .sm\:py-3 {
    padding-top: 3px;
    padding-bottom: 3px;
  }

  .sm\:px-3 {
    padding-left: 3px;
    padding-right: 3px;
  }

  .sm\:py-4 {
    padding-top: 4px;
    padding-bottom: 4px;
  }

  .sm\:px-4 {
    padding-left: 4px;
    padding-right: 4px;
  }

  .sm\:py-5 {
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .sm\:px-5 {
    padding-left: 5px;
    padding-right: 5px;
  }

  .sm\:py-10 {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .sm\:px-10 {
    padding-left: 10px;
    padding-right: 10px;
  }

  .sm\:py-15 {
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .sm\:px-15 {
    padding-left: 15px;
    padding-right: 15px;
  }

  .sm\:py-20 {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .sm\:px-20 {
    padding-left: 20px;
    padding-right: 20px;
  }

  .sm\:py-25 {
    padding-top: 25px;
    padding-bottom: 25px;
  }

  .sm\:px-25 {
    padding-left: 25px;
    padding-right: 25px;
  }

  .sm\:py-30 {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .sm\:px-30 {
    padding-left: 30px;
    padding-right: 30px;
  }

  .sm\:py-35 {
    padding-top: 35px;
    padding-bottom: 35px;
  }

  .sm\:px-35 {
    padding-left: 35px;
    padding-right: 35px;
  }

  .sm\:py-40 {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .sm\:px-40 {
    padding-left: 40px;
    padding-right: 40px;
  }

  .sm\:py-45 {
    padding-top: 45px;
    padding-bottom: 45px;
  }

  .sm\:px-45 {
    padding-left: 45px;
    padding-right: 45px;
  }

  .sm\:py-50 {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .sm\:px-50 {
    padding-left: 50px;
    padding-right: 50px;
  }

  .sm\:py-55 {
    padding-top: 55px;
    padding-bottom: 55px;
  }

  .sm\:px-55 {
    padding-left: 55px;
    padding-right: 55px;
  }

  .sm\:py-60 {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .sm\:px-60 {
    padding-left: 60px;
    padding-right: 60px;
  }

  .sm\:py-65 {
    padding-top: 65px;
    padding-bottom: 65px;
  }

  .sm\:px-65 {
    padding-left: 65px;
    padding-right: 65px;
  }

  .sm\:py-70 {
    padding-top: 70px;
    padding-bottom: 70px;
  }

  .sm\:px-70 {
    padding-left: 70px;
    padding-right: 70px;
  }

  .sm\:py-75 {
    padding-top: 75px;
    padding-bottom: 75px;
  }

  .sm\:px-75 {
    padding-left: 75px;
    padding-right: 75px;
  }

  .sm\:py-80 {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  .sm\:px-80 {
    padding-left: 80px;
    padding-right: 80px;
  }

  .sm\:py-85 {
    padding-top: 85px;
    padding-bottom: 85px;
  }

  .sm\:px-85 {
    padding-left: 85px;
    padding-right: 85px;
  }

  .sm\:py-90 {
    padding-top: 90px;
    padding-bottom: 90px;
  }

  .sm\:px-90 {
    padding-left: 90px;
    padding-right: 90px;
  }

  .sm\:py-95 {
    padding-top: 95px;
    padding-bottom: 95px;
  }

  .sm\:px-95 {
    padding-left: 95px;
    padding-right: 95px;
  }

  .sm\:py-100 {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .sm\:px-100 {
    padding-left: 100px;
    padding-right: 100px;
  }

  .sm\:py-105 {
    padding-top: 105px;
    padding-bottom: 105px;
  }

  .sm\:px-105 {
    padding-left: 105px;
    padding-right: 105px;
  }

  .sm\:py-110 {
    padding-top: 110px;
    padding-bottom: 110px;
  }

  .sm\:px-110 {
    padding-left: 110px;
    padding-right: 110px;
  }

  .sm\:py-115 {
    padding-top: 115px;
    padding-bottom: 115px;
  }

  .sm\:px-115 {
    padding-left: 115px;
    padding-right: 115px;
  }

  .sm\:py-120 {
    padding-top: 120px;
    padding-bottom: 120px;
  }

  .sm\:px-120 {
    padding-left: 120px;
    padding-right: 120px;
  }

  .sm\:py-px {
    padding-top: 1px;
    padding-bottom: 1px;
  }

  .sm\:px-px {
    padding-left: 1px;
    padding-right: 1px;
  }

  .sm\:py-0\.5 {
    padding-top: 0.5px;
    padding-bottom: 0.5px;
  }

  .sm\:px-0\.5 {
    padding-left: 0.5px;
    padding-right: 0.5px;
  }

  .sm\:py-1\.5 {
    padding-top: 1.5px;
    padding-bottom: 1.5px;
  }

  .sm\:px-1\.5 {
    padding-left: 1.5px;
    padding-right: 1.5px;
  }

  .sm\:py-2\.5 {
    padding-top: 2.5px;
    padding-bottom: 2.5px;
  }

  .sm\:px-2\.5 {
    padding-left: 2.5px;
    padding-right: 2.5px;
  }

  .sm\:py-3\.5 {
    padding-top: 3.5px;
    padding-bottom: 3.5px;
  }

  .sm\:px-3\.5 {
    padding-left: 3.5px;
    padding-right: 3.5px;
  }

  .sm\:pt-0 {
    padding-top: 0px;
  }

  .sm\:pr-0 {
    padding-right: 0px;
  }

  .sm\:pb-0 {
    padding-bottom: 0px;
  }

  .sm\:pl-0 {
    padding-left: 0px;
  }

  .sm\:pt-1 {
    padding-top: 1px;
  }

  .sm\:pr-1 {
    padding-right: 1px;
  }

  .sm\:pb-1 {
    padding-bottom: 1px;
  }

  .sm\:pl-1 {
    padding-left: 1px;
  }

  .sm\:pt-2 {
    padding-top: 2px;
  }

  .sm\:pr-2 {
    padding-right: 2px;
  }

  .sm\:pb-2 {
    padding-bottom: 2px;
  }

  .sm\:pl-2 {
    padding-left: 2px;
  }

  .sm\:pt-3 {
    padding-top: 3px;
  }

  .sm\:pr-3 {
    padding-right: 3px;
  }

  .sm\:pb-3 {
    padding-bottom: 3px;
  }

  .sm\:pl-3 {
    padding-left: 3px;
  }

  .sm\:pt-4 {
    padding-top: 4px;
  }

  .sm\:pr-4 {
    padding-right: 4px;
  }

  .sm\:pb-4 {
    padding-bottom: 4px;
  }

  .sm\:pl-4 {
    padding-left: 4px;
  }

  .sm\:pt-5 {
    padding-top: 5px;
  }

  .sm\:pr-5 {
    padding-right: 5px;
  }

  .sm\:pb-5 {
    padding-bottom: 5px;
  }

  .sm\:pl-5 {
    padding-left: 5px;
  }

  .sm\:pt-10 {
    padding-top: 10px;
  }

  .sm\:pr-10 {
    padding-right: 10px;
  }

  .sm\:pb-10 {
    padding-bottom: 10px;
  }

  .sm\:pl-10 {
    padding-left: 10px;
  }

  .sm\:pt-15 {
    padding-top: 15px;
  }

  .sm\:pr-15 {
    padding-right: 15px;
  }

  .sm\:pb-15 {
    padding-bottom: 15px;
  }

  .sm\:pl-15 {
    padding-left: 15px;
  }

  .sm\:pt-20 {
    padding-top: 20px;
  }

  .sm\:pr-20 {
    padding-right: 20px;
  }

  .sm\:pb-20 {
    padding-bottom: 20px;
  }

  .sm\:pl-20 {
    padding-left: 20px;
  }

  .sm\:pt-25 {
    padding-top: 25px;
  }

  .sm\:pr-25 {
    padding-right: 25px;
  }

  .sm\:pb-25 {
    padding-bottom: 25px;
  }

  .sm\:pl-25 {
    padding-left: 25px;
  }

  .sm\:pt-30 {
    padding-top: 30px;
  }

  .sm\:pr-30 {
    padding-right: 30px;
  }

  .sm\:pb-30 {
    padding-bottom: 30px;
  }

  .sm\:pl-30 {
    padding-left: 30px;
  }

  .sm\:pt-35 {
    padding-top: 35px;
  }

  .sm\:pr-35 {
    padding-right: 35px;
  }

  .sm\:pb-35 {
    padding-bottom: 35px;
  }

  .sm\:pl-35 {
    padding-left: 35px;
  }

  .sm\:pt-40 {
    padding-top: 40px;
  }

  .sm\:pr-40 {
    padding-right: 40px;
  }

  .sm\:pb-40 {
    padding-bottom: 40px;
  }

  .sm\:pl-40 {
    padding-left: 40px;
  }

  .sm\:pt-45 {
    padding-top: 45px;
  }

  .sm\:pr-45 {
    padding-right: 45px;
  }

  .sm\:pb-45 {
    padding-bottom: 45px;
  }

  .sm\:pl-45 {
    padding-left: 45px;
  }

  .sm\:pt-50 {
    padding-top: 50px;
  }

  .sm\:pr-50 {
    padding-right: 50px;
  }

  .sm\:pb-50 {
    padding-bottom: 50px;
  }

  .sm\:pl-50 {
    padding-left: 50px;
  }

  .sm\:pt-55 {
    padding-top: 55px;
  }

  .sm\:pr-55 {
    padding-right: 55px;
  }

  .sm\:pb-55 {
    padding-bottom: 55px;
  }

  .sm\:pl-55 {
    padding-left: 55px;
  }

  .sm\:pt-60 {
    padding-top: 60px;
  }

  .sm\:pr-60 {
    padding-right: 60px;
  }

  .sm\:pb-60 {
    padding-bottom: 60px;
  }

  .sm\:pl-60 {
    padding-left: 60px;
  }

  .sm\:pt-65 {
    padding-top: 65px;
  }

  .sm\:pr-65 {
    padding-right: 65px;
  }

  .sm\:pb-65 {
    padding-bottom: 65px;
  }

  .sm\:pl-65 {
    padding-left: 65px;
  }

  .sm\:pt-70 {
    padding-top: 70px;
  }

  .sm\:pr-70 {
    padding-right: 70px;
  }

  .sm\:pb-70 {
    padding-bottom: 70px;
  }

  .sm\:pl-70 {
    padding-left: 70px;
  }

  .sm\:pt-75 {
    padding-top: 75px;
  }

  .sm\:pr-75 {
    padding-right: 75px;
  }

  .sm\:pb-75 {
    padding-bottom: 75px;
  }

  .sm\:pl-75 {
    padding-left: 75px;
  }

  .sm\:pt-80 {
    padding-top: 80px;
  }

  .sm\:pr-80 {
    padding-right: 80px;
  }

  .sm\:pb-80 {
    padding-bottom: 80px;
  }

  .sm\:pl-80 {
    padding-left: 80px;
  }

  .sm\:pt-85 {
    padding-top: 85px;
  }

  .sm\:pr-85 {
    padding-right: 85px;
  }

  .sm\:pb-85 {
    padding-bottom: 85px;
  }

  .sm\:pl-85 {
    padding-left: 85px;
  }

  .sm\:pt-90 {
    padding-top: 90px;
  }

  .sm\:pr-90 {
    padding-right: 90px;
  }

  .sm\:pb-90 {
    padding-bottom: 90px;
  }

  .sm\:pl-90 {
    padding-left: 90px;
  }

  .sm\:pt-95 {
    padding-top: 95px;
  }

  .sm\:pr-95 {
    padding-right: 95px;
  }

  .sm\:pb-95 {
    padding-bottom: 95px;
  }

  .sm\:pl-95 {
    padding-left: 95px;
  }

  .sm\:pt-100 {
    padding-top: 100px;
  }

  .sm\:pr-100 {
    padding-right: 100px;
  }

  .sm\:pb-100 {
    padding-bottom: 100px;
  }

  .sm\:pl-100 {
    padding-left: 100px;
  }

  .sm\:pt-105 {
    padding-top: 105px;
  }

  .sm\:pr-105 {
    padding-right: 105px;
  }

  .sm\:pb-105 {
    padding-bottom: 105px;
  }

  .sm\:pl-105 {
    padding-left: 105px;
  }

  .sm\:pt-110 {
    padding-top: 110px;
  }

  .sm\:pr-110 {
    padding-right: 110px;
  }

  .sm\:pb-110 {
    padding-bottom: 110px;
  }

  .sm\:pl-110 {
    padding-left: 110px;
  }

  .sm\:pt-115 {
    padding-top: 115px;
  }

  .sm\:pr-115 {
    padding-right: 115px;
  }

  .sm\:pb-115 {
    padding-bottom: 115px;
  }

  .sm\:pl-115 {
    padding-left: 115px;
  }

  .sm\:pt-120 {
    padding-top: 120px;
  }

  .sm\:pr-120 {
    padding-right: 120px;
  }

  .sm\:pb-120 {
    padding-bottom: 120px;
  }

  .sm\:pl-120 {
    padding-left: 120px;
  }

  .sm\:pt-px {
    padding-top: 1px;
  }

  .sm\:pr-px {
    padding-right: 1px;
  }

  .sm\:pb-px {
    padding-bottom: 1px;
  }

  .sm\:pl-px {
    padding-left: 1px;
  }

  .sm\:pt-0\.5 {
    padding-top: 0.5px;
  }

  .sm\:pr-0\.5 {
    padding-right: 0.5px;
  }

  .sm\:pb-0\.5 {
    padding-bottom: 0.5px;
  }

  .sm\:pl-0\.5 {
    padding-left: 0.5px;
  }

  .sm\:pt-1\.5 {
    padding-top: 1.5px;
  }

  .sm\:pr-1\.5 {
    padding-right: 1.5px;
  }

  .sm\:pb-1\.5 {
    padding-bottom: 1.5px;
  }

  .sm\:pl-1\.5 {
    padding-left: 1.5px;
  }

  .sm\:pt-2\.5 {
    padding-top: 2.5px;
  }

  .sm\:pr-2\.5 {
    padding-right: 2.5px;
  }

  .sm\:pb-2\.5 {
    padding-bottom: 2.5px;
  }

  .sm\:pl-2\.5 {
    padding-left: 2.5px;
  }

  .sm\:pt-3\.5 {
    padding-top: 3.5px;
  }

  .sm\:pr-3\.5 {
    padding-right: 3.5px;
  }

  .sm\:pb-3\.5 {
    padding-bottom: 3.5px;
  }

  .sm\:pl-3\.5 {
    padding-left: 3.5px;
  }
}
// -----------🌈🌈🌈🌈🌈🌈🌈🌈  medium screen 🌈🌈🌈🌈🌈🌈🌈🌈-----------
@include lg {
  .md\:p-0 {
    padding: 0px;
  }

  .md\:p-1 {
    padding: 1px;
  }

  .md\:p-2 {
    padding: 2px;
  }

  .md\:p-3 {
    padding: 3px;
  }

  .md\:p-4 {
    padding: 4px;
  }

  .md\:p-5 {
    padding: 5px;
  }

  .md\:p-10 {
    padding: 10px;
  }

  .md\:p-15 {
    padding: 15px;
  }

  .md\:p-20 {
    padding: 20px;
  }

  .md\:p-25 {
    padding: 25px;
  }

  .md\:p-30 {
    padding: 30px;
  }

  .md\:p-35 {
    padding: 35px;
  }

  .md\:p-40 {
    padding: 40px;
  }

  .md\:p-45 {
    padding: 45px;
  }

  .md\:p-50 {
    padding: 50px;
  }

  .md\:p-55 {
    padding: 55px;
  }

  .md\:p-60 {
    padding: 60px;
  }

  .md\:p-65 {
    padding: 65px;
  }

  .md\:p-70 {
    padding: 70px;
  }

  .md\:p-75 {
    padding: 75px;
  }

  .md\:p-80 {
    padding: 80px;
  }

  .md\:p-85 {
    padding: 85px;
  }

  .md\:p-90 {
    padding: 90px;
  }

  .md\:p-95 {
    padding: 95px;
  }

  .md\:p-100 {
    padding: 100px;
  }

  .md\:p-105 {
    padding: 105px;
  }

  .md\:p-110 {
    padding: 110px;
  }

  .md\:p-115 {
    padding: 115px;
  }

  .md\:p-120 {
    padding: 120px;
  }

  .md\:p-px {
    padding: 1px;
  }

  .md\:p-0\.5 {
    padding: 0.5px;
  }

  .md\:p-1\.5 {
    padding: 1.5px;
  }

  .md\:p-2\.5 {
    padding: 2.5px;
  }

  .md\:p-3\.5 {
    padding: 3.5px;
  }

  .md\:py-0 {
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .md\:px-0 {
    padding-left: 0px;
    padding-right: 0px;
  }

  .md\:py-1 {
    padding-top: 1px;
    padding-bottom: 1px;
  }

  .md\:px-1 {
    padding-left: 1px;
    padding-right: 1px;
  }

  .md\:py-2 {
    padding-top: 2px;
    padding-bottom: 2px;
  }

  .md\:px-2 {
    padding-left: 2px;
    padding-right: 2px;
  }

  .md\:py-3 {
    padding-top: 3px;
    padding-bottom: 3px;
  }

  .md\:px-3 {
    padding-left: 3px;
    padding-right: 3px;
  }

  .md\:py-4 {
    padding-top: 4px;
    padding-bottom: 4px;
  }

  .md\:px-4 {
    padding-left: 4px;
    padding-right: 4px;
  }

  .md\:py-5 {
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .md\:px-5 {
    padding-left: 5px;
    padding-right: 5px;
  }

  .md\:py-10 {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .md\:px-10 {
    padding-left: 10px;
    padding-right: 10px;
  }

  .md\:py-15 {
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .md\:px-15 {
    padding-left: 15px;
    padding-right: 15px;
  }

  .md\:py-20 {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .md\:px-20 {
    padding-left: 20px;
    padding-right: 20px;
  }

  .md\:py-25 {
    padding-top: 25px;
    padding-bottom: 25px;
  }

  .md\:px-25 {
    padding-left: 25px;
    padding-right: 25px;
  }

  .md\:py-30 {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .md\:px-30 {
    padding-left: 30px;
    padding-right: 30px;
  }

  .md\:py-35 {
    padding-top: 35px;
    padding-bottom: 35px;
  }

  .md\:px-35 {
    padding-left: 35px;
    padding-right: 35px;
  }

  .md\:py-40 {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .md\:px-40 {
    padding-left: 40px;
    padding-right: 40px;
  }

  .md\:py-45 {
    padding-top: 45px;
    padding-bottom: 45px;
  }

  .md\:px-45 {
    padding-left: 45px;
    padding-right: 45px;
  }

  .md\:py-50 {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .md\:px-50 {
    padding-left: 50px;
    padding-right: 50px;
  }

  .md\:py-55 {
    padding-top: 55px;
    padding-bottom: 55px;
  }

  .md\:px-55 {
    padding-left: 55px;
    padding-right: 55px;
  }

  .md\:py-60 {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .md\:px-60 {
    padding-left: 60px;
    padding-right: 60px;
  }

  .md\:py-65 {
    padding-top: 65px;
    padding-bottom: 65px;
  }

  .md\:px-65 {
    padding-left: 65px;
    padding-right: 65px;
  }

  .md\:py-70 {
    padding-top: 70px;
    padding-bottom: 70px;
  }

  .md\:px-70 {
    padding-left: 70px;
    padding-right: 70px;
  }

  .md\:py-75 {
    padding-top: 75px;
    padding-bottom: 75px;
  }

  .md\:px-75 {
    padding-left: 75px;
    padding-right: 75px;
  }

  .md\:py-80 {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  .md\:px-80 {
    padding-left: 80px;
    padding-right: 80px;
  }

  .md\:py-85 {
    padding-top: 85px;
    padding-bottom: 85px;
  }

  .md\:px-85 {
    padding-left: 85px;
    padding-right: 85px;
  }

  .md\:py-90 {
    padding-top: 90px;
    padding-bottom: 90px;
  }

  .md\:px-90 {
    padding-left: 90px;
    padding-right: 90px;
  }

  .md\:py-95 {
    padding-top: 95px;
    padding-bottom: 95px;
  }

  .md\:px-95 {
    padding-left: 95px;
    padding-right: 95px;
  }

  .md\:py-100 {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .md\:px-100 {
    padding-left: 100px;
    padding-right: 100px;
  }

  .md\:py-105 {
    padding-top: 105px;
    padding-bottom: 105px;
  }

  .md\:px-105 {
    padding-left: 105px;
    padding-right: 105px;
  }

  .md\:py-110 {
    padding-top: 110px;
    padding-bottom: 110px;
  }

  .md\:px-110 {
    padding-left: 110px;
    padding-right: 110px;
  }

  .md\:py-115 {
    padding-top: 115px;
    padding-bottom: 115px;
  }

  .md\:px-115 {
    padding-left: 115px;
    padding-right: 115px;
  }

  .md\:py-120 {
    padding-top: 120px;
    padding-bottom: 120px;
  }

  .md\:px-120 {
    padding-left: 120px;
    padding-right: 120px;
  }

  .md\:py-px {
    padding-top: 1px;
    padding-bottom: 1px;
  }

  .md\:px-px {
    padding-left: 1px;
    padding-right: 1px;
  }

  .md\:py-0\.5 {
    padding-top: 0.5px;
    padding-bottom: 0.5px;
  }

  .md\:px-0\.5 {
    padding-left: 0.5px;
    padding-right: 0.5px;
  }

  .md\:py-1\.5 {
    padding-top: 1.5px;
    padding-bottom: 1.5px;
  }

  .md\:px-1\.5 {
    padding-left: 1.5px;
    padding-right: 1.5px;
  }

  .md\:py-2\.5 {
    padding-top: 2.5px;
    padding-bottom: 2.5px;
  }

  .md\:px-2\.5 {
    padding-left: 2.5px;
    padding-right: 2.5px;
  }

  .md\:py-3\.5 {
    padding-top: 3.5px;
    padding-bottom: 3.5px;
  }

  .md\:px-3\.5 {
    padding-left: 3.5px;
    padding-right: 3.5px;
  }

  .md\:pt-0 {
    padding-top: 0px;
  }

  .md\:pr-0 {
    padding-right: 0px;
  }

  .md\:pb-0 {
    padding-bottom: 0px;
  }

  .md\:pl-0 {
    padding-left: 0px;
  }

  .md\:pt-1 {
    padding-top: 1px;
  }

  .md\:pr-1 {
    padding-right: 1px;
  }

  .md\:pb-1 {
    padding-bottom: 1px;
  }

  .md\:pl-1 {
    padding-left: 1px;
  }

  .md\:pt-2 {
    padding-top: 2px;
  }

  .md\:pr-2 {
    padding-right: 2px;
  }

  .md\:pb-2 {
    padding-bottom: 2px;
  }

  .md\:pl-2 {
    padding-left: 2px;
  }

  .md\:pt-3 {
    padding-top: 3px;
  }

  .md\:pr-3 {
    padding-right: 3px;
  }

  .md\:pb-3 {
    padding-bottom: 3px;
  }

  .md\:pl-3 {
    padding-left: 3px;
  }

  .md\:pt-4 {
    padding-top: 4px;
  }

  .md\:pr-4 {
    padding-right: 4px;
  }

  .md\:pb-4 {
    padding-bottom: 4px;
  }

  .md\:pl-4 {
    padding-left: 4px;
  }

  .md\:pt-5 {
    padding-top: 5px;
  }

  .md\:pr-5 {
    padding-right: 5px;
  }

  .md\:pb-5 {
    padding-bottom: 5px;
  }

  .md\:pl-5 {
    padding-left: 5px;
  }

  .md\:pt-10 {
    padding-top: 10px;
  }

  .md\:pr-10 {
    padding-right: 10px;
  }

  .md\:pb-10 {
    padding-bottom: 10px;
  }

  .md\:pl-10 {
    padding-left: 10px;
  }

  .md\:pt-15 {
    padding-top: 15px;
  }

  .md\:pr-15 {
    padding-right: 15px;
  }

  .md\:pb-15 {
    padding-bottom: 15px;
  }

  .md\:pl-15 {
    padding-left: 15px;
  }

  .md\:pt-20 {
    padding-top: 20px;
  }

  .md\:pr-20 {
    padding-right: 20px;
  }

  .md\:pb-20 {
    padding-bottom: 20px;
  }

  .md\:pl-20 {
    padding-left: 20px;
  }

  .md\:pt-25 {
    padding-top: 25px;
  }

  .md\:pr-25 {
    padding-right: 25px;
  }

  .md\:pb-25 {
    padding-bottom: 25px;
  }

  .md\:pl-25 {
    padding-left: 25px;
  }

  .md\:pt-30 {
    padding-top: 30px;
  }

  .md\:pr-30 {
    padding-right: 30px;
  }

  .md\:pb-30 {
    padding-bottom: 30px;
  }

  .md\:pl-30 {
    padding-left: 30px;
  }

  .md\:pt-35 {
    padding-top: 35px;
  }

  .md\:pr-35 {
    padding-right: 35px;
  }

  .md\:pb-35 {
    padding-bottom: 35px;
  }

  .md\:pl-35 {
    padding-left: 35px;
  }

  .md\:pt-40 {
    padding-top: 40px;
  }

  .md\:pr-40 {
    padding-right: 40px;
  }

  .md\:pb-40 {
    padding-bottom: 40px;
  }

  .md\:pl-40 {
    padding-left: 40px;
  }

  .md\:pt-45 {
    padding-top: 45px;
  }

  .md\:pr-45 {
    padding-right: 45px;
  }

  .md\:pb-45 {
    padding-bottom: 45px;
  }

  .md\:pl-45 {
    padding-left: 45px;
  }

  .md\:pt-50 {
    padding-top: 50px;
  }

  .md\:pr-50 {
    padding-right: 50px;
  }

  .md\:pb-50 {
    padding-bottom: 50px;
  }

  .md\:pl-50 {
    padding-left: 50px;
  }

  .md\:pt-55 {
    padding-top: 55px;
  }

  .md\:pr-55 {
    padding-right: 55px;
  }

  .md\:pb-55 {
    padding-bottom: 55px;
  }

  .md\:pl-55 {
    padding-left: 55px;
  }

  .md\:pt-60 {
    padding-top: 60px;
  }

  .md\:pr-60 {
    padding-right: 60px;
  }

  .md\:pb-60 {
    padding-bottom: 60px;
  }

  .md\:pl-60 {
    padding-left: 60px;
  }

  .md\:pt-65 {
    padding-top: 65px;
  }

  .md\:pr-65 {
    padding-right: 65px;
  }

  .md\:pb-65 {
    padding-bottom: 65px;
  }

  .md\:pl-65 {
    padding-left: 65px;
  }

  .md\:pt-70 {
    padding-top: 70px;
  }

  .md\:pr-70 {
    padding-right: 70px;
  }

  .md\:pb-70 {
    padding-bottom: 70px;
  }

  .md\:pl-70 {
    padding-left: 70px;
  }

  .md\:pt-75 {
    padding-top: 75px;
  }

  .md\:pr-75 {
    padding-right: 75px;
  }

  .md\:pb-75 {
    padding-bottom: 75px;
  }

  .md\:pl-75 {
    padding-left: 75px;
  }

  .md\:pt-80 {
    padding-top: 80px;
  }

  .md\:pr-80 {
    padding-right: 80px;
  }

  .md\:pb-80 {
    padding-bottom: 80px;
  }

  .md\:pl-80 {
    padding-left: 80px;
  }

  .md\:pt-85 {
    padding-top: 85px;
  }

  .md\:pr-85 {
    padding-right: 85px;
  }

  .md\:pb-85 {
    padding-bottom: 85px;
  }

  .md\:pl-85 {
    padding-left: 85px;
  }

  .md\:pt-90 {
    padding-top: 90px;
  }

  .md\:pr-90 {
    padding-right: 90px;
  }

  .md\:pb-90 {
    padding-bottom: 90px;
  }

  .md\:pl-90 {
    padding-left: 90px;
  }

  .md\:pt-95 {
    padding-top: 95px;
  }

  .md\:pr-95 {
    padding-right: 95px;
  }

  .md\:pb-95 {
    padding-bottom: 95px;
  }

  .md\:pl-95 {
    padding-left: 95px;
  }

  .md\:pt-100 {
    padding-top: 100px;
  }

  .md\:pr-100 {
    padding-right: 100px;
  }

  .md\:pb-100 {
    padding-bottom: 100px;
  }

  .md\:pl-100 {
    padding-left: 100px;
  }

  .md\:pt-105 {
    padding-top: 105px;
  }

  .md\:pr-105 {
    padding-right: 105px;
  }

  .md\:pb-105 {
    padding-bottom: 105px;
  }

  .md\:pl-105 {
    padding-left: 105px;
  }

  .md\:pt-110 {
    padding-top: 110px;
  }

  .md\:pr-110 {
    padding-right: 110px;
  }

  .md\:pb-110 {
    padding-bottom: 110px;
  }

  .md\:pl-110 {
    padding-left: 110px;
  }

  .md\:pt-115 {
    padding-top: 115px;
  }

  .md\:pr-115 {
    padding-right: 115px;
  }

  .md\:pb-115 {
    padding-bottom: 115px;
  }

  .md\:pl-115 {
    padding-left: 115px;
  }

  .md\:pt-120 {
    padding-top: 120px;
  }

  .md\:pr-120 {
    padding-right: 120px;
  }

  .md\:pb-120 {
    padding-bottom: 120px;
  }

  .md\:pl-120 {
    padding-left: 120px;
  }

  .md\:pt-px {
    padding-top: 1px;
  }

  .md\:pr-px {
    padding-right: 1px;
  }

  .md\:pb-px {
    padding-bottom: 1px;
  }

  .md\:pl-px {
    padding-left: 1px;
  }

  .md\:pt-0\.5 {
    padding-top: 0.5px;
  }

  .md\:pr-0\.5 {
    padding-right: 0.5px;
  }

  .md\:pb-0\.5 {
    padding-bottom: 0.5px;
  }

  .md\:pl-0\.5 {
    padding-left: 0.5px;
  }

  .md\:pt-1\.5 {
    padding-top: 1.5px;
  }

  .md\:pr-1\.5 {
    padding-right: 1.5px;
  }

  .md\:pb-1\.5 {
    padding-bottom: 1.5px;
  }

  .md\:pl-1\.5 {
    padding-left: 1.5px;
  }

  .md\:pt-2\.5 {
    padding-top: 2.5px;
  }

  .md\:pr-2\.5 {
    padding-right: 2.5px;
  }

  .md\:pb-2\.5 {
    padding-bottom: 2.5px;
  }

  .md\:pl-2\.5 {
    padding-left: 2.5px;
  }

  .md\:pt-3\.5 {
    padding-top: 3.5px;
  }

  .md\:pr-3\.5 {
    padding-right: 3.5px;
  }

  .md\:pb-3\.5 {
    padding-bottom: 3.5px;
  }

  .md\:pl-3\.5 {
    padding-left: 3.5px;
  }
}
