.header__1 {
    position: relative;
    background: $color_white;
    border-bottom: solid 1px $color_stroke;
    width: 100%;
    white-space: nowrap;
    &.header_wallet {
        display: none;
    }
    .header__menu {
        -ms-overflow-style: none;
        /* IE and Edge */
        scrollbar-width: none;
        /* Firefox */
        &::-webkit-scrollbar {
            display: none;
        }
        a {
            white-space: nowrap;
        }
    }
    .wrapper {
        padding: 7px 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 10px;
    }
    .header__logo {
        img {
            height: 4.5rem;
        }
    }
    .is_new {
        position: relative;
        @include before {
            left: -6px;
            top: -3px;
            height: 5px;
            width: 5px;
            border-radius: 22px;
            background: $color_red;
        }
    }
    .has_popup,
    .has_popup2 {
        &:hover {
            .menu__popup,
            .menu__popup2 {
                visibility: visible;
                opacity: 1;
                transform: translateY(0) translateX(-50%);
            }
        }
    }
    .has_popup,
    .has_popup2 {
        position: relative;
        >a {
            display: flex;
            align-items: center;
        }
        .menu__popup,
        .menu__popup2 {
            position: absolute;
            padding: 30px;
            top: 3rem;
            left: 50%;
            overflow: hidden;
            border-radius: 10px;
            background: $color_white;
            transition: all 0.35s ease-in-out;
            box-shadow: $shadow_1;
            border: solid 1px $color_stroke;
            visibility: hidden;
            opacity: 0;
            transform: translateY(-1rem) translateX(-50%);
            max-width: 20rem;
            width: max-content;
            z-index: 9;
            a {
                display: flex;
                align-items: center;
                color: $color_black;
                &:hover {
                    color: $color_brand;
                }
                i {
                    font-size: 20px;
                    margin-right: 5px;
                }
            }
            &.visible {
                visibility: visible;
                opacity: 1;
                transform: translateY(0) translateX(-50%);
            }
        }
        .menu__popup2 {
            width: 13rem; // edited 27
            max-width: inherit;
        }
    }
    .header__search {
        &.in_mobile {
            @include tablet_vert_screen {
                display: block;
                width: 100%;
            }
        }
        .ant-select-selector{
            margin-top: 7px;
        }
        box-shadow: $shadow_2;
        background: $color_white;
        height: 3rem;
        width: 20rem;
        border-radius: 10px;
        position: relative;
        transition: all 0.2s ease-in-out;
        border: solid 1px transparent;
        &:hover {
            background: $color_white;
            box-shadow: $shadow_1;
            border: solid 1px $color_stroke;
        }
        input {
            width: 100%;
            height: 100%;
            background: transparent !important;
            border: none !important;
            padding-left: calc(2rem + 20px);
        }
        .header__result {
            border: none;
            background: $color_white;
            border-radius: $border_radius * 10;
            height: 2rem;
            width: 2rem;
            position: absolute;
            left: 10px;
            top: 50%;
            transform: translateY(-50%);
            display: flex;
            justify-content: center;
            i {
                color: $color_black;
                top: 3px;
            }
        }
    }
    .header__burger {
        display: none;
        @include tablet_vert_screen {
            display: block;
            position: relative;
            width: 32px;
            height: 32px;
            background: none;
            -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
            &.active {
                &:before {
                    transform: translateY(0) rotate(-45deg);
                }
                &:after {
                    transform: translateY(0) rotate(45deg);
                }
            }
            &:before,
            &:after {
                content: "";
                position: absolute;
                top: 16px;
                left: 6px;
                width: 20px;
                height: 2px;
                background: $color_text;
                border-radius: 2px;
                transition: transform 0.2s;
                cursor: pointer;
            }
            &:before {
                transform: translateY(-4px);
            }
            &:after {
                transform: translateY(3px);
            }
        }
    }
    .header__mobile {
        visibility: hidden;
        position: absolute;
        overflow: hidden;
        top: 4.9rem;
        left: 0;
        right: 0;
        border-radius: 10px;
        margin: 0 !important;
        background: $color_white;
        width: 100%;
        padding: 40px 15px;
        transition: all 0.45s $transition_function;
        opacity: 0;
        box-shadow: $shadow_1;
        z-index: 999;
        transform: translateY(-1rem) translateX(0);
        @include tablet_vert_screen {
            &.visible {
                visibility: visible;
                opacity: 1;
                transform: translateY(0) translateX(0);
            }
        }
        &__menu {
            ul {
                display: flex;
                flex-direction: column;
            }
        }
    }
    @include lg {
        .header__search {
            display: none;
        }
    }
    @include tablet_vert_screen {
        .header__search,
        .header__menu {
            display: none;
        }
    }
    .header__btns {
        display: block;
        @include tablet_vert_screen {
            display: none;
        }
    }
    .header__avatar {
        border-radius: $border_radius * 100;
        border: solid 1px $color_stroke;
        background: $color_light;
        display: flex;
        align-items: center;
        cursor: pointer;
        transition: all 0.2s ease-in-out;
        z-index: 9;
        @include sm_screen {
            display: none !important;
        }
        &:hover {
            background: $color_hard_light;
        }
        .avatar {
            height: 2.4rem;
            width: 2.4rem;
            border-radius: $border_radius * 100;
            object-fit: cover;
        }
        .price {
            padding: 0 10px 0 20px;
        }
        .avatar_popup {
            position: absolute;
            padding: 30px;
            top: 6rem;
            overflow: hidden;
            border-radius: 10px;
            background: $color_white;
            transition: all 0.25s ease-in-out;
            box-shadow: $shadow_1;
            border: solid 1px $color_stroke;
            visibility: hidden;
            opacity: 0;
            transform: translateY(-1rem);
            &.visible {
                visibility: visible;
                opacity: 1;
                transform: translateY(0);
            }
            .links {
                display: flex;
                flex-direction: column;
                a {
                    color: $color_black;
                    display: flex;
                    align-items: center;
                    i {
                        margin-right: 5px;
                        font-size: 20px;
                    }
                    &:hover {
                        color: $color_brand;
                    }
                }
            }
        }
    }
    .header__notifications {
        .js-notifications-icon {
            cursor: pointer;
        }
        @include sm_screen {
            display: none !important;
        }
        .notifications_popup {
            position: absolute;
            padding: 30px;
            top: 6rem;
            overflow: hidden;
            border-radius: 10px;
            background: $color_white;
            transition: all 0.25s ease-in-out;
            box-shadow: $shadow_1;
            border: solid 1px $color_stroke;
            visibility: hidden;
            opacity: 0;
            transform: translateY(-1rem);
            max-width: 30rem;
            z-index: 9;
            &.visible {
                visibility: visible;
                opacity: 1;
                transform: translateY(0);
            }
            .item {
                position: relative;
                padding-right: 40px;
            }
            .circle {
                position: absolute;
                top: 50%;
                right: 0;
                transform: translateY(-50%);
                width: 12px;
                height: 12px;
                border-radius: 50%;
                background: $color_brand;
            }
            a {
                h6 {
                    transition: all 0.25s ease-in-out;
                    &:hover {
                        color: $color_brand;
                    }
                }
            }
            .badge {
                border-radius: 20px;
                padding: 8px 16px 6px;
                font-size: 14px;
                background: $color_black;
            }
            .thumb {
                height: 3rem;
                width: 3rem;
                border-radius: $border_radius * 100;
                background: $color_stroke;
            }
            .links {
                display: flex;
                flex-direction: column;
                a {
                    color: $color_black;
                    display: flex;
                    align-items: center;
                    i {
                        margin-right: 5px;
                        font-size: 20px;
                    }
                }
            }
        }
    }
    &.is__dark {
        border-bottom: 1px solid rgba(255, 255, 255, 0.082);
        background: $color_black;
        * {
            color: $color_white !important;
        }
        .header__search {
            background: $color_dark;
            &:hover,
            &:focus {
                border: none !important;
            }
        }
        .header__result {
            background: $color_black !important;
        }
        .menu__popup {
            background: #142c3f;
            border: none;
        }
    }
}

.btn-prim {
    background: $color_brand !important;
    border: $color_brand;
    &:hover {
        background: $color_brand !important;
        border: $color_brand;
    }
}